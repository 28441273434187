import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { login, resendVerification } from '../services/api';
import logoImage from './images/Atomwissen_Logo.png';
import './Login.css';

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [verificationStatus, setVerificationStatus] = useState({
    needsVerification: false,
    resendLoading: false,
    resendMessage: ''
  });
  const navigate = useNavigate();

  // All your existing handlers remain the same
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
    if (name === 'email') {
      setVerificationStatus({
        needsVerification: false,
        resendLoading: false,
        resendMessage: ''
      });
    }
  };

  const handleResendVerification = async () => {
    setVerificationStatus(prev => ({
      ...prev,
      resendLoading: true,
      resendMessage: ''
    }));

    try {
      await resendVerification(formData.email);
      setVerificationStatus(prev => ({
        ...prev,
        resendMessage: 'Verification email sent! Please check your inbox.'
      }));
    } catch (err) {
      setVerificationStatus(prev => ({
        ...prev,
        resendMessage: err.detail || 'Failed to resend verification email. Please try again.'
      }));
    } finally {
      setVerificationStatus(prev => ({
        ...prev,
        resendLoading: false
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setVerificationStatus(prev => ({
      ...prev,
      needsVerification: false,
      resendMessage: ''
    }));

    try {
      const response = await login(formData.email, formData.password);
      if (response.access_token) {
        navigate('/home');
      }
    } catch (err) {
      if (err.detail === 'Email not verified') {
        setVerificationStatus(prev => ({
          ...prev,
          needsVerification: true
        }));
        setError('Please verify your email before logging in. Click the button below to resend the verification email.');
      } else if (err.detail === 'Incorrect email or password') {
        setError('Invalid email or password. Please try again.');
      } else {
        setError(err.detail || 'Login failed. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-wrapper">
      <div 
        className="background-container"/>
      <div className="login-container">
        <div className="login-box">
          <div className="login-content">
            <div className="logo-container">
              <img src={logoImage} alt="Atomwissen Logo" className="logo-image" />
            </div>
            <h2>Welcome Back</h2>
            <p>Login to continue your CFA exam preparation</p>

            <form onSubmit={handleSubmit} className="login-form">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={loading}
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  disabled={loading}
                  required
                  className="form-input"
                />
              </div>

              {error && <div className="error-message">{error}</div>}
              
              {verificationStatus.needsVerification && (
                <div className="verification-section">
                  <button 
                    type="button"
                    onClick={handleResendVerification}
                    disabled={verificationStatus.resendLoading}
                    className="resend-button"
                  >
                    {verificationStatus.resendLoading ? 'Sending...' : 'Resend Verification Email'}
                  </button>
                  {verificationStatus.resendMessage && (
                    <div className={`verification-message ${
                      verificationStatus.resendMessage.includes('sent') ? 'success' : 'error'
                    }`}>
                      {verificationStatus.resendMessage}
                    </div>
                  )}
                </div>
              )}

              <div className="forgot-password">
                <Link to="/forgot-password" className="text-button">
                  Forgot Password?
                </Link>
              </div>

              <button 
                type="submit" 
                disabled={loading}
                className="submit-button"
              >
                {loading ? 'Logging in...' : 'Login'}
              </button>
            </form>

            <div className="login-footer">
              <p>
                Don't have an account?{' '}
                <Link to="/signup" className="text-button">
                  Sign Up
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;