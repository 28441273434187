// src/components/VerifyEmail.js

import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { verifyEmail } from '../services/api';
import './VerifyEmail.css';

function VerifyEmail() {
  const [status, setStatus] = useState({
    loading: true,
    message: 'Verifying your email address...',
    type: 'info'
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    const verifyUserEmail = async () => {
      if (!token) {
        setStatus({
          loading: false,
          message: 'Verification link is invalid. No token provided.',
          type: 'error'
        });
        return;
      }

      try {
        const response = await verifyEmail(token);
        
        // Check if the error indicates the token was already used
        if (response?.detail?.includes('already been used')) {
          setStatus({
            loading: false,
            message: 'Your email has already been verified. Please proceed to login.',
            type: 'success'  // Changed to success since this isn't really an error
          });
          
          setTimeout(() => {
            navigate('/login?verified=true');
          }, 3000);
          return;
        }
        
        // Handle successful verification
        setStatus({
          loading: false,
          message: 'Email verified successfully! You will be redirected to login.',
          type: 'success'
        });

        setTimeout(() => {
          navigate('/login?verified=true');
        }, 3000);
        
      } catch (error) {
        console.error('Verification error details:', error);
        
        let errorMessage = 'Email verification failed.';
        const errorDetail = error.response?.data?.detail || error.message;
        
        if (errorDetail?.includes('expired')) {
          errorMessage = 'This verification link has expired. Please request a new one.';
        } else if (errorDetail?.includes('invalid')) {
          errorMessage = 'Invalid verification link. Please check your email for the correct link.';
        }

        setStatus({
          loading: false,
          message: errorMessage,
          type: 'error'
        });
      }
    };

    verifyUserEmail();
  }, [token, navigate]);

  return (
    <div className="verify-email-container">
      <div className="verify-email-box">
        <div className="verify-email-content">
          <h1>AtomWissen</h1>
          <h2>Email Verification</h2>

          <div className={`status-container ${status.type}`}>
            {status.loading ? (
              <div className="loading-spinner">
                <div className="spinner"></div>
              </div>
            ) : (
              <div className="status-message">
                <span className="status-icon">
                  {status.type === 'success' ? '✓' : status.type === 'error' ? '✗' : 'ⓘ'}
                </span>
                <p>{status.message}</p>
              </div>
            )}
          </div>

          {(!status.loading && status.type === 'error') && (
            <div className="action-buttons">
              <Link to="/login" className="primary-button">
                Go to Login
              </Link>
              {status.message.includes('expired') && (
                <Link to="/signup" className="secondary-button">
                  Register Again
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;