// src/components/ResetPassword.js

import React, { useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { resetPassword } from '../services/api';
import './ResetPassword.css';

function ResetPassword() {
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const validatePassword = (password) => {
    const requirements = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*]/.test(password)
    };

    return Object.values(requirements).every(Boolean);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setMessage({ text: '', type: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ text: '', type: '' });

    if (formData.newPassword !== formData.confirmPassword) {
      setMessage({ text: 'Passwords do not match', type: 'error' });
      return;
    }

    if (!validatePassword(formData.newPassword)) {
      setMessage({
        text: 'Password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters',
        type: 'error'
      });
      return;
    }

    setLoading(true);
    try {
      const response = await resetPassword(token, formData.newPassword);
      setMessage({
        text: response.detail || 'Password reset successful. Redirecting to login...',
        type: 'success'
      });

      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setMessage({
        text: error.detail || 'Failed to reset password. Please try again.',
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  if (!token) {
    return (
      <div className="reset-password-container">
        <div className="reset-password-box">
          <div className="reset-password-content">
            <h1>AtomWissen</h1>
            <h2>Invalid Reset Link</h2>
            <p className="error-message">
              The password reset link is invalid or has expired.
            </p>
            <Link to="/forgot-password" className="request-link">
              Request a new reset link
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="reset-password-container">
      <div className="reset-password-box">
        <div className="reset-password-content">
          <h1>AtomWissen</h1>
          <h2>Create New Password</h2>
          <p>Please enter your new password</p>

          <form onSubmit={handleSubmit} className="reset-password-form">
            <div className="form-group">
              <input
                type="password"
                name="newPassword"
                placeholder="New Password"
                value={formData.newPassword}
                onChange={handleChange}
                disabled={loading}
                required
                className="form-input"
              />
            </div>

            <div className="form-group">
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm New Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                disabled={loading}
                required
                className="form-input"
              />
            </div>

            {message.text && (
              <div className={`message-box ${message.type}`}>
                {message.text}
              </div>
            )}

            <button 
              type="submit" 
              disabled={loading}
              className="submit-button"
            >
              {loading ? 'Resetting Password...' : 'Reset Password'}
            </button>

            <Link to="/login" className="back-link">
              Back to Login
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

