// src/services/api.js

import axios from 'axios';

// Configure default timeout for all requests
axios.defaults.timeout = 30000; // 30 seconds timeout

// Get base URL from environment variable with fallback
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://cfa-project-bff.onrender.com';

// Helper function to get auth headers - keeping this useful utility
const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

// Authentication Endpoints

export const login = async (email, password) => {
  try {
    // Create form data as expected by OAuth2 standard
    const formData = new URLSearchParams();
    formData.append('username', email);  // OAuth2 uses username field for email
    formData.append('password', password);

    const response = await axios.post(
      `${API_BASE_URL}/api/auth/token`,  // Updated path
      formData.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );

    if (response.data.access_token) {
      localStorage.setItem('token', response.data.access_token);
      console.log('Token stored successfully');
    }

    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error.response?.data || error;
  }
};

export const register = async (email, password) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/users/register`,  // Updated path
      { email, password }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

// Password Reset Endpoints

export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/users/forgot-password`,  // Updated path
      { email }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const resetPassword = async (token, new_password) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/users/reset-password`,  // Updated path
      { token, new_password }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// Email Verification Endpoints

export const verifyEmail = async (token) => {
  try {
    // Important: Make sure to pass the token as a query parameter
    const response = await axios.get(
      `${API_BASE_URL}/api/users/verify-email?token=${token}`,
    );
    return response.data;
  } catch (error) {
    console.error('Verification error:', error);
    throw error.response?.data || error;
  }
};

export const resendVerification = async (email) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/auth/resend-verification`,
      { email }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// Question Endpoints

export const getNextQuestion = async (topicId) => {
  try {
    return await axiosRetry(async () => {
      console.log('Requesting question for topic:', topicId);

      if (!topicId) {
        throw new Error('Topic ID is required');
      }

      const url = `${API_BASE_URL}/api/questions/next?topic_id=${topicId}`;
      console.log('Request URL:', url);

      const response = await axios.get(
        url,
        getAuthHeaders()
      );

      console.log('Question data received:', response.data);
      
      return response.data;
    });
  } catch (error) {
    console.error('Error details:', {
      message: error.message,
      response: error.response?.data,
      topicId: topicId
    });
    
    if (error.response?.status === 401) {
      logout();
    }
    throw error;
  }
};

export const submitAnswer = async (submission) => {
  try {
    return await axiosRetry(async () => {
      console.log('Submitting answer:', submission);

      if (!submission.question_id || !submission.selected_option) {
        throw new Error('Invalid submission data');
      }

      const response = await axios.post(
        `${API_BASE_URL}/api/questions/submit`,
        submission,
        getAuthHeaders()
      );

      console.log('Submit answer response:', response.data);

      return response.data;
    });
  } catch (error) {
    console.error('Submit answer error:', {
      message: error.message,
      response: error.response?.data,
      submission: submission
    });

    if (error.response?.status === 401) {
      logout();
    }
    throw error;
  }
};

// New Subscription Endpoints

export const startTrial = async (topicId) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/subscriptions/trial/${topicId}`,
      {},
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// Access Validation Endpoint

export const validateAccess = async (topicId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/access/validate/${topicId}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// Retry logic - keeping this useful feature
const axiosRetry = async (fn, retries = 3, delay = 1000) => {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) throw error;
    
    if (
      error.code === 'ECONNABORTED' || 
      error.response?.status === 503 ||
      error.response?.status === 504
    ) {
      await new Promise(resolve => setTimeout(resolve, delay));
      return axiosRetry(fn, retries - 1, delay * 1.5);
    }
    
    throw error;
  }
};

// Global response interceptor for handling 401 errors
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

export const getUserProfile = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/users/me`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// User subscription topics details

export const getTopics = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/access/topics`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getUserLearningAccess = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/access/learning-access`,
      getAuthHeaders()
    );
    const topicsResponse = await axios.get(
      `${API_BASE_URL}/api/access/topics`,
      getAuthHeaders()
    );
    return {
      access: response.data,
      topics: topicsResponse.data
    };
  } catch (error) {
    console.error('Learning access error:', error);
    throw error.response?.data || error;
  }
};