// src/components/ForgotPassword.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { forgotPassword } from '../services/api';
import logoImage from './images/Atomwissen_Logo.png';
import './ForgotPassword.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ text: '', type: '' });

    try {
      const response = await forgotPassword(email);
      setMessage({
        text: response.detail || 'Password reset link has been sent to your email.',
        type: 'success'
      });
      setEmail('');
      
      // Automatically redirect to login after successful submission
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      if (error.detail?.includes('does not exist')) {
        setMessage({
          text: 'No account found with this email address.',
          type: 'error'
        });
      } else {
        setMessage({
          text: error.detail || 'Failed to send reset link. Please try again.',
          type: 'error'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-box">
        <div className="forgot-password-content">
        <div className="logo-container">
            <img src={logoImage} alt="Atomwissen Logo" className="logo-image" />
          </div>
          <h2>Reset Password</h2>
          <p>Enter your email address to receive a password reset link</p>

          <form onSubmit={handleSubmit} className="forgot-password-form">
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
                required
                className="form-input"
              />
            </div>

            {message.text && (
              <div className={`message-box ${message.type}`}>
                {message.text}
              </div>
            )}

            <button 
              type="submit" 
              disabled={loading}
              className="submit-button"
            >
              {loading ? 'Sending Link...' : 'Send Reset Link'}
            </button>

            <Link to="/login" className="back-link">
              Back to Login
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;