import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import {
  getUserProfile,
  validateAccess,
  getUserLearningAccess
} from '../services/api';
import logoImage from './images/Atomwissen_Logo.png';
import './Home.css';

// Menu Icon Component
const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#29335C"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="3" y1="12" x2="21" y2="12"></line>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <line x1="3" y1="18" x2="21" y2="18"></line>
  </svg>
);

// User Menu Component
function UserMenu({ cfaLevel, onChangeCfaLevel, onLogout }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="user-menu-button"
      >
        <MenuIcon />
      </button>

      {isOpen && (
        <div className="user-menu-dropdown">
          <button
            onClick={() => {
              onChangeCfaLevel();
              setIsOpen(false);
            }}
            className="user-menu-item"
          >
            Change CFA Level
          </button>
          <button
            onClick={() => {
              onLogout();
              setIsOpen(false);
            }}
            className="user-menu-item"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

// Updated TopicItem Component
function TopicItem({ topic, onTopicSelect, onSubscribe }) {
  const handleClick = (e) => {
    e.stopPropagation(); // Prevent triggering the parent onClick
    if (topic.status === 'active') {
      onTopicSelect(topic);
    } else {
      onSubscribe(topic.id);
    }
  };

  return (
    <div className="topic-item">
      <div className="topic-header">
        <div className="topic-title">
          {topic.name}
        </div>
        <button
          onClick={handleClick}
          className={topic.status === 'active' ? 'practice-button' : 'subscribe-button'}
        >
          {topic.status === 'active' ? 'Open!' : 'Subscribe'}
        </button>
      </div>
    </div>
  );
}

function ModuleItem({ module, onStartPractice }) {
  return (
    <div className="module-item">
      <div className="module-header">
        <div className="module-title">
          {module.name}
        </div>
        <button
          onClick={() => onStartPractice(module.topicId, 'practice', module.id)}
          className="module-practice-button"
        >
          Practice Now!
        </button>
      </div>
    </div>
  );
}

function TopicDetails({ selectedTopic, masteryStarted, masteryData, modules, onStartPractice, onStartMastery }) {
  return (
    <div className="topic-details">
      <div className="assisted-mastery-section">
        <h2 className="section-title">Assisted Mastery</h2>
        {!masteryStarted ? (
          <div className="mastery-cta">
            <button 
              className="start-mastery-button"
              onClick={() => onStartMastery(selectedTopic.id)}
            >
              Start Your Mastery Journey
            </button>
          </div>
        ) : (
          <div className="mastery-progress">
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={masteryData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="score" stroke="#29335C" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
      
      <div className="divider" />
      
      <div className="self-paced-section">
        <h2 className="section-title">Self-Paced Practice</h2>
        <div className="modules-container">
          {modules.map((module) => (
            <ModuleItem
              key={module.id}
              module={module}
              onStartPractice={onStartPractice}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function Home() {
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [modules, setModules] = useState([]);
  const [masteryStarted, setMasteryStarted] = useState(false);
  const [masteryData, setMasteryData] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const [profile, accessData] = await Promise.all([
          getUserProfile(),
          getUserLearningAccess()
        ]);

        const formattedTopics = accessData.topics?.map((topic) => ({
          id: topic.id,
          name: topic.topic_name,
          status: accessData.access.accessible_topics?.includes(topic.id)
            ? 'active'
            : 'inactive'
        })) || [];

        setUserProfile(profile);
        setTopics(formattedTopics);
        setError('');
        setLoading(false);
      } catch (err) {
        setError(
          err.response?.data?.detail || 'Failed to load user data'
        );
        setLoading(false);
        console.error('Data fetch error:', err);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (selectedTopic) {
      // Mock modules data - replace with actual API call
      const mockModules = [
        { id: 1, name: "Module 1: Introduction", topicId: selectedTopic.id },
        { id: 2, name: "Module 2: Core Concepts", topicId: selectedTopic.id },
        { id: 3, name: "Module 3: Advanced Topics", topicId: selectedTopic.id },
      ];
      setModules(mockModules);

      // Mock mastery data - replace with actual API call
      const mockMasteryData = [
        { date: "Week 1", score: 65 },
        { date: "Week 2", score: 72 },
        { date: "Week 3", score: 78 },
        { date: "Week 4", score: 85 },
      ];
      setMasteryData(mockMasteryData);
    }
  }, [selectedTopic]);

  const handleStartPractice = async (topicId, mode, moduleId = null) => {
    try {
      const accessCheck = await validateAccess(topicId);
      if (accessCheck.has_access) {
        navigate('/question', { 
          state: { 
            topicId: topicId,
            mode: mode,
            moduleId: moduleId
          }
        });
      } else {
        setError(accessCheck.message || 'Access validation failed');
      }
    } catch (err) {
      console.error('Practice start error:', err);
      setError('Failed to start practice. Please try again.');
    }
  };

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
    setMasteryStarted(false); // Reset mastery status when switching topics
  };

  const handleStartMastery = async (topicId) => {
    try {
      // Here you would typically make an API call to start the mastery journey
      setMasteryStarted(true);
    } catch (err) {
      setError('Failed to start mastery journey. Please try again.');
      console.error('Mastery start error:', err);
    }
  };

  const handleSubscribe = async (topicId) => {
    try {
      const accessData = await getUserLearningAccess();
      const formattedTopics = accessData.topics?.map((topic) => ({
        id: topic.id,
        name: topic.topic_name,
        status: accessData.access.accessible_topics?.includes(topic.id)
          ? 'active'
          : 'inactive'
      })) || [];
      setTopics(formattedTopics);
    } catch (err) {
      setError('Failed to subscribe. Please try again.');
      console.error('Subscription error:', err);
    }
  };

  const handleChangeCfaLevel = () => {
    console.log('Change CFA Level clicked');
    // Add your CFA level change logic here
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-text">Loading...</div>
      </div>
    );
  }

  return (
    <div className="home-container">
      <div className="home-header-wrapper">
        <header className="home-header">
          <div className="logo-section">
            <div className="logo-container">
              <img src={logoImage} alt="Atomwissen Logo" className="logo-image" />
            </div>
            {userProfile && (
              <div className="header-subtitle">
                CFA Level {userProfile.cfa_level}
              </div>
            )}
          </div>
          <UserMenu
            cfaLevel={userProfile?.cfa_level}
            onChangeCfaLevel={handleChangeCfaLevel}
            onLogout={handleLogout}
          />
        </header>
      </div>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <div className="main-content">
        <main className="topics-container">
          {topics.map((topic) => (
            <TopicItem
              key={topic.id}
              topic={topic}
              onTopicSelect={handleTopicSelect}
              onSubscribe={handleSubscribe}
            />
          ))}
        </main>
        
        <aside className="topic-details-container">
          {selectedTopic ? (
            <TopicDetails
              selectedTopic={selectedTopic}
              masteryStarted={masteryStarted}
              masteryData={masteryData}
              modules={modules}
              onStartPractice={handleStartPractice}
              onStartMastery={handleStartMastery}
            />
          ) : (
            <div className="topic-details-placeholder">
              <h2 className="section-title">Select a Topic</h2>
              <p>Choose a topic to see details and start practicing</p>
            </div>
          )}
        </aside>
      </div>
    </div>
  );
}

export default Home;